<template>
  <div id="Cosmopolitan">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>

<script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";

export default {
  name: "Cosmopolitan",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: [
        {
          mapBg: require("@/assets/maps/bg-venetian-route.jpg"),
          mapLine: "CosmopolitanPath",
          venueBg: require("@/assets/maps/cosmo.png"),
          venue: "The Cosmopolitan",
          distance: "2.4",
          singleLineVenue: true,
          threeLineVenue: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss scoped">
</style>